import axios from "axios";

export const baseUrl = process.env.REACT_APP_API_URL;
export const baseApiUrl = baseUrl + "/api/";

export const apiCall = axios.create({
  baseURL: baseApiUrl,
  timeout: 15000,
  validateStatus: (status) => status < 500,
  transformRequest: [
    function (data, headers) {
      headers["Content-Type"] = "application/json";
      headers["Authorization"] = `Bearer ${
        fetchData("user_data")?.token || ""
      }`;
      return JSON.stringify(data);
    },
  ],
});

export const fetchData = (type) => {
  if (localStorage.getItem(type)) return JSON.parse(localStorage.getItem(type));
  return "";
};

export const storeData = (type, value) => {
  localStorage.setItem(type, JSON.stringify(value));
};
