import React, { createContext, useEffect, useState } from "react";
import { Alert, Snackbar } from "@mui/material";
import { storeData, fetchData } from "./helpers";

const MainContext = createContext();

const MainContextProvider = ({ children }) => {
  const [user, setUser] = useState(fetchData("user_data") || {});
  const [msg, setMsg] = useState("");

  useEffect(() => storeData("user_data", user), [user]);

  return (
    <MainContext.Provider
      value={{
        user,
        setUser,
        setMsg,
      }}
    >
      {msg && (
        <Snackbar
          open
          autoHideDuration={2000}
          onClose={() => setMsg("")}
          anchorOrigin={{ horizontal: "center", vertical: "top" }}
          message={msg}
        >
          <Alert
            severity={
              msg.includes("ucces")
                ? "success"
                : msg.includes("soon")
                ? "warning"
                : "error"
            }
          >
            {msg}
          </Alert>
        </Snackbar>
      )}
      {children}
    </MainContext.Provider>
  );
};

export { MainContext, MainContextProvider };
