import { lazy, Suspense } from "react";
import "./App.css";
import "./fonts.css";
import { Backdrop, CircularProgress } from "@mui/material";
import { Navigate, Route, Routes } from "react-router-dom";
import { MainContextProvider } from "./utils/context";
const HomePage = lazy(() => import("./components/Homepage/HomePage"));
const Onboarding = lazy(() => import("./components/Onboarding/Onboarding"));
function App() {
  return (
    <MainContextProvider>
      <div className="App">
        <Suspense
          fallback={
            <Backdrop
              open
              sx={{
                color: "rgba(30, 144, 255, 1)",
              }}
            >
              <CircularProgress
                color="inherit"
                role="status"
                aria-label="Loading"
              />
            </Backdrop>
          }
        >
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/features" element={<HomePage />} />
            <Route path="/onboarding" element={<Onboarding />} />
            <Route path="/about" element={<HomePage />} />
            <Route path="/works" element={<HomePage />} />
            <Route path="*" element={<Navigate replace to="/" />} />
          </Routes>
        </Suspense>
      </div>
    </MainContextProvider>
  );
}

export default App;
